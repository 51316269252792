$color-wood: #80461b;

.tabla {
  width: 100vh;
  min-width: 100vh;
  height: 100vh;
  background: #000;
  position: relative;
  user-select: none;

  &.rotated {
    transform: rotate(180deg);
  }

  .outs {
    position: absolute;
    top: 4vh;
    left: 50vh - 0.5 * 7vh;
    width: 7vh;
    height: 4 * 7vh;
    // outline: 2px dotted red;
    justify-content: flex-end;
    flex-direction: column;

    &.light {
      top: unset;
      bottom: 4vh;
      transform: rotate(180deg);
    }
  }

  .selected-stack {
    li {
      &:nth-last-child(1) {
        box-shadow: inset 0 0 0 1vh transparentize(lime, 0.1),
          inset 0 0 0 7vh transparentize(lime, 0.4);
      }
    }
  }

  li.piece {
    width: 6.8vh;
    height: 6.8vh;
    margin: 0 0.1vh 0.1vh 0.1vh;
    border-radius: 50%;
    box-shadow: 0 0 0.1vh black;

    &.dark {
      background: radial-gradient(
        darken($dark, 10%) 0%,
        darken($dark, 20%) 48%,
        darken($dark, 10%) 50%,
        darken($dark, 20%) 90%
      );
    }

    &.light {
      background: radial-gradient(
        lighten($light, 30%) 0%,
        lighten($light, 0%) 48%,
        lighten($light, 10%) 50%,
        lighten($light, 45%) 90%
      );
    }

    &:hover {
      cursor: pointer;
    }
  }

  .dice-cube {
    $dice-size: 80%;
    $dice-margin: 0.5 * (100% - $dice-size);
    $dice-padding: $dice-margin * 0.666;

    width: $dice-size;
    background-color: $light;
    height: 0;
    padding: $dice-padding;
    padding-bottom: $dice-size - $dice-padding * 1.5;
    padding-top: $dice-padding * 1.5;
    box-shadow: 0 0 1vh 0.2vh black;
    border-radius: 20%;
    margin: $dice-margin;

    display: flex;
    flex-direction: row;
    flex-wrap: wrap;

    .dot {
      $dot-size: 25%;
      border-radius: 50%;
      background-color: $dark;
      width: $dot-size;
      height: 0;
      padding-bottom: $dot-size;
      margin: 2.5%;

      &.hidden {
        visibility: hidden;
      }
    }

    &.dark {
      background-color: $dark;
      .dot {
        background-color: $light;
      }
    }

    &.used {
      opacity: 0.5;
    }
  }

  .dice {
    position: absolute;
    top: 50vh - 2 * 7vh;
    left: 50vh - 0.5 * 7vh;
    width: 7vh;
    height: 4 * 7vh;
    flex-direction: column;

    span {
      font-size: 8vh;
      height: 7vh;
      z-index: 2;
      position: relative;
      color: darken($dark, 20%);

      &.used {
        opacity: 0.4;
      }

      &:before,
      &:after {
        content: "";
        width: 0;
        height: 0;
        position: absolute;
        top: 2.5vh;
        border-radius: 0.1vh;
      }

      &:before {
        border: 2.5vh solid $light;
        z-index: -1;
        box-shadow: 0 0 1vh 0.5vh black;
        border-radius: 1vh;
        top: 1.5vh;
      }

      &:after {
        border: 1.5vh solid transparent;
        outline: 1.3vh solid $light;
        z-index: 1;
      }
    }

    &.dark {
      span {
        color: lighten($light, 20%);

        &:before {
          border: 2.5vh solid $dark;
        }

        &:after {
          outline: 1.3vh solid $dark;
        }
      }
    }
  }

  .half {
    width: 49vh;
    height: 98vh;
    background-color: $color-wood;
    position: absolute;
    top: 1vh;
    border: 3.5vh solid $color-wood;
    box-shadow: 0 0 0.3vh black, inset 0 0 3vh black;
    flex-direction: column;

    &.left {
      left: 1vh;
    }

    &.right {
      right: 1vh;
    }

    .quarter {
      position: absolute;
      width: 6 * 7vh;
      height: 6 * 7vh;
      transform: rotate(0deg);

      .out {
        background-color: lime;
        opacity: 0.4;
        width: 0.5 * 7vh;
        height: 6 * 7vh;
        top: 0;
        right: -0.5 * 7vh;
        position: absolute;
        cursor: pointer;

        &.bottom {
          left: -0.5 * 7vh;
          right: unset;
        }
      }

      &.top {
        top: 0;
      }

      .pos > span {
        display: none;
      }

      &.bottom {
        bottom: 0;
        transform: rotate(180deg);

        .pos > span {
          transform: rotate(180deg);
        }
      }

      .pos {
        top: 0;
        width: 1 * 7vh;
        height: 6 * 7vh;
        position: relative;

        border-left: 3.5vh solid transparent;
        border-right: 3.5vh solid transparent;
        border-top: 6 * 7vh solid transparentize($dark, 0.3);

        &:nth-child(odd) {
          border-top: 6 * 7vh solid transparentize($light, 0.7);
        }

        > span {
          position: absolute;
          top: -6.45 * 7vh;
          font-size: 1.8vh;
        }

        ul {
          position: absolute;
          top: -6 * 7vh;
          left: -0.5 * 7vh;
          width: 1 * 7vh;
          height: 6 * 7vh;
          //   outline: 2px dotted red;

          display: flex;
          flex-direction: column;
          justify-content: flex-start;

          background: linear-gradient(
            to bottom,
            transparentize(black, 0.8) 0%,
            transparent 5%
          );

          @import "stacks";

          .drop {
            background: limegreen;
            width: 7vh;
            height: 7vh;
            border-radius: 50%;
            opacity: 0.5;

            &:hover {
              cursor: pointer;
              opacity: 1;
            }
          }
        }
      }
    }
  }

  .pips {
    position: absolute;
    top: 50vh;
    width: 9vh;
    height: 5vh;
    margin-top: -2.5vh;
    font-size: 3.6vh;
    font-weight: 600;
    letter-spacing: 0.1vh;

    border-radius: 0 1vh 1vh 0;
    right: 16vh;
    background-color: darken($dark, 10);
    color: lighten($light, 20);
    box-shadow: inset 0 0 2vh black;

    &.light {
      border-radius: 1vh 0 0 1vh;
      right: 25vh;
      background-color: lighten($light, 20);
      color: darken($dark, 10);
      box-shadow: inset 0 0 2vh transparentize(black, 0.5);
    }
  }

  &.rotated {
    .pips {
      transform: rotate(180deg);

      border-radius: 1vh 0 0 1vh;

      &.light {
        border-radius: 0 1vh 1vh 0;
      }
    }
  }
}

@import "controls";
