&.stack-6 {
  @include stack(6);
}

&.stack-7 {
  @include stack(7);
}

&.stack-8 {
  @include stack(8);
}

&.stack-9 {
  @include stack(9);
}

&.stack-10 {
  @include stack(10);
}

&.stack-11 {
  @include stack(11);
}

&.stack-12 {
  @include stack(12);
}

&.stack-13 {
  @include stack(13);
}

&.stack-14 {
  @include stack(14);
}

&.stack-15 {
  @include stack(15);
}
