.controls {
  height: 100vh;
  background: #111;
  width: 100%;
  padding: 2vh;

  flex-direction: column;

  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
    justify-content: flex-end;
    width: 100vh;
  }

  img {
    border-radius: 50%;
  }
}
