$dark: #6e260e;
$light: #c2b280;

body {
  background-color: #111;
  color: white;
  overflow: hidden;

  * {
    box-sizing: border-box;
    margin: 0;
    padding: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: all 0.1s ease;
  }
}

h1,
h2,
h3 {
  white-space: nowrap;
}

main {
  // padding: 1em;
  width: 100%;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;

  @media only screen and (max-width: 767px) {
    flex-direction: column-reverse;
  }
}

.msg {
  display: flex;
  padding: 0;
  margin-bottom: 1em;
  border-radius: 999px;

  background-color: transparentize(white, 0.8);

  img {
    width: 2em;
    height: 2em;
    border-radius: 50%;
  }

  p {
    padding: 0 0.5em;
    font-size: 0.9em;
  }

  &.sent {
    background-color: transparentize(blue, 0.8);
    align-self: flex-end;
  }

  &.received {
    background-color: transparentize(red, 0.8);
  }
}

button {
  width: 100%;
  padding: 1em;
  border: 0;
  border-radius: 0.5em;
  background: darkblue;
  color: white;
  margin: 0.5em 0;
  cursor: pointer;

  &.dark {
    background-color: darken($dark, 5%);
    color: lighten($light, 10%);
  }

  &.light {
    background-color: lighten($light, 5%);
    color: darken($dark, 10%);
  }

  &.big {
    font-size: 2em;
    padding: 2em 0;
    font-weight: bold;
    margin: 0.2em;
  }

  &.accept {
    background: green;
    &:focus-visible,
    &:hover {
      &:not([disabled]) {
        background: lime;
      }
    }
  }

  &:focus-visible,
  &:hover {
    &:not([disabled]) {
      opacity: 0.8;
    }
  }

  &:disabled {
    opacity: 0.5;
    background: #333;
    cursor: not-allowed;
  }

  &.hidden {
    opacity: 1;
    position: absolute;
    top: -9999px;
  }
}

img {
  &.small {
    height: 2em;
    width: 2em;
    margin: -0.5em 0.5em;
  }
}

@import "mixins";
@import "tabla";

.mouse {
  width: 50px;
  height: 50px;
  background: transparentize(red, 0.8);
  outline: 5px solid red;
  border-radius: 50%;
  position: fixed;
  z-index: 999;
}
